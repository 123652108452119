import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main, jobs, video } from '../content/kariera'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Headline from '../components/Headline'
import Jobs from '../sections/Jobs'
import Section from '../components/Section'
import Video from '../components/Video'
import Jubileusze from '../sections/kariera/Jubileusze'
import CareerAdditionalText from '../sections/kariera/CareerAdditionalText'

const Karieria = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/kariera.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/kariera.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} />
      <Main data={main} h={1} />
      <Video data={video}></Video>
      <CareerAdditionalText />
      <Jubileusze />
      <Section top>
        <Headline
          title={jobs.title[lang]}
          desc={jobs.desc?.[lang]}
          large
          gradient
        />
      </Section>
      <Jobs minimal />
    </Layout>
  )
}

export default Karieria
